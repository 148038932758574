import React from "react"
import Styled from "styled-components"

const Svg = Styled.svg`
    cursor: pointer;
    margin-right: 5px;
    margin-left: 5px;
    path {
        fill: black;
    }
    &:hover {
        path {
            fill: ${({ theme }) => theme.primary};
        }
    }
`

const Check = ({ width, color, onClick, title }) => (
  <Svg
    aria-hidden="true"
    focusable="false"
    data-prefix="far"
    data-icon="check-square"
    role="img"
    viewBox="0 0 448 512"
    width={width ? width : 15}
    onClick={onClick}
  >
    {title && <title>{title}</title>}
    <path
      fill={color ? color : "currentColor"}
      d="M400 32H48C21.49 32 0 53.49 0 80v352c0 26.51 21.49 48 48 48h352c26.51 0 48-21.49 48-48V80c0-26.51-21.49-48-48-48zm0 400H48V80h352v352zm-35.864-241.724L191.547 361.48c-4.705 4.667-12.303 4.637-16.97-.068l-90.781-91.516c-4.667-4.705-4.637-12.303.069-16.971l22.719-22.536c4.705-4.667 12.303-4.637 16.97.069l59.792 60.277 141.352-140.216c4.705-4.667 12.303-4.637 16.97.068l22.536 22.718c4.667 4.706 4.637 12.304-.068 16.971z"
    ></path>
  </Svg>
)

export default Check
