import { useStaticQuery, graphql } from "gatsby"

const useIndexImages = () => {
  const data = useStaticQuery(
    graphql`
      query indexImages {
        header: allFile(filter: { name: { eq: "home_header_1200x798" } }) {
          edges {
            node {
              childImageSharp {
                fluid(quality: 100, maxWidth: 1200) {
                  ...GatsbyImageSharpFluid_withWebp
                }
              }
              id
            }
          }
        }
        headerLogo: allFile(filter: { name: { eq: "logo_white_940x940" } }) {
          edges {
            node {
              childImageSharp {
                fluid(quality: 100, maxWidth: 940) {
                  ...GatsbyImageSharpFluid_withWebp
                }
              }
              id
            }
          }
        }
        navLogo: allFile(filter: { name: { eq: "logo_white_favi_396x396" } }) {
          edges {
            node {
              childImageSharp {
                fluid(quality: 100, maxWidth: 396) {
                  ...GatsbyImageSharpFluid_withWebp
                }
              }
              id
            }
          }
        }
        logo: allFile(filter: { name: { eq: "logo_white_600x600" } }) {
          edges {
            node {
              childImageSharp {
                fluid(quality: 100, maxWidth: 600) {
                  ...GatsbyImageSharpFluid_withWebp
                }
              }
              id
            }
          }
        }
        examples: allFile(filter: { relativeDirectory: { eq: "_examples" } }) {
          edges {
            node {
              childImageSharp {
                fluid(quality: 100, maxWidth: 600) {
                  ...GatsbyImageSharpFluid
                }
              }
              name
            }
          }
        }
      }
    `
  )
  // gatsby images
  const header = data.header.edges[0].node.childImageSharp.fluid
  const headerLogo = data.headerLogo.edges[0].node.childImageSharp.fluid
  const navLogo = data.navLogo.edges[0].node.childImageSharp.fluid
  const logo = data.logo.edges[0].node.childImageSharp.fluid
  const examples = data.examples.edges
  return {
    header,
    headerLogo,
    navLogo,
    logo,
    examples,
  }
}

export default useIndexImages
