const DEV = false
export const SERVERLESS_URL = DEV
  ? "http://localhost:9000/.netlify/functions/contact"
  : "/.netlify/functions/contact"

export const SECTIONS = [
  { name: `About`, path: `about` },
  { name: `Contact`, path: `contact` },
  { name: `Services`, path: `services` },
  { name: `Quote`, path: `quote` },
  { name: `Testimonials`, path: `testimonials` },
]

export const PRICE = 35000 // $350.00
export const BASE_SQFT = 3000 // 3,000 sqft
export const PER_500_SQFT = 5000 // $50.00
export const SQFT_OPTIONS = {
  [0]: 0,
  [500]: 0,
  [1000]: 0,
  [1500]: 0,
  [2000]: 0,
  [2500]: 0,
  [3000]: 0,
  [3500]: 1,
  [4000]: 2,
  [4500]: 3,
  [5000]: 4,
  [5500]: 5,
  [6000]: 6,
  [6500]: 7,
  [7000]: 8,
  [7500]: 9,
  [8000]: 10,
  [8500]: 11,
  [9000]: 12,
  [9500]: 13,
  [10000]: 14,
  [10500]: 15,
  [11000]: 16,
  [11500]: 17,
  [12000]: 18,
  [12500]: 19,
  [13000]: 20,
  [13500]: 21,
  [14000]: 22,
  [14500]: 23,
  [15000]: 24,
  [15500]: 25,
  [16000]: 26,
  [16500]: 27,
  [17000]: 28,
  [17500]: 29,
  [18000]: 30,
  [18500]: 31,
  [19000]: 32,
  [19500]: 33,
  [20000]: 34,
}

export const TESTIMONIALS = [
  {
    testimonial: `"My wife and I chose to use Adam at Southern Utah Home Inspection to do our home inpsection.  He was fast and efficient! Thank you Adam."`,
    client: `~ The Jennings`,
  },
  {
    testimonial: `"Southern Utah Home Inspection has got everything I need. It's all good. Just what I was looking for."`,
    client: `~ Jojo F`,
  },
  {
    testimonial: `"Keep up the excellent work. Southern Utah Home Inspection is great!"`,
    client: `~ Courtney S`,
  },
]
