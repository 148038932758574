const insert = (main, ins, pos) => {
  return main.slice(0, pos) + ins + main.slice(pos)
}
/** ------------------------------------------------------------------
 *! Convert To Proper Dollars
 */
export const ConvertDollars = target => {
  return insert(`${target}`, ".", `${target}`.length - 2)
}
/** ------------------------------------------------------------------
 *! Convert To Proper Sqft
 */
export const ConvertSqft = target => {
  if (`${target}`.length < 4) return target
  return insert(`${target}`, ",", `${target}`.length - 3)
}
/** ------------------------------------------------------------------
 *! Generate Array with Range/Increment
 */
export const range = (start, end, step = 1) => {
  let index = -1
  let length = Math.max(Math.ceil((end - start) / step), 0)
  const result = new Array(length)
  console.log(result)
  while (length--) {
    result[++index] = start
    start += step
  }
  return result
}
/** ------------------------------------------------------------------
 *! Local Storage Helpers
 */
export const GetToken = () => {
  if (typeof window !== "undefined") {
    return localStorage.getItem("7ea2ceccf2e673357879358b18e3145b")
  }
  return null
}
export const ClearPersist = () => {
  if (typeof window !== "undefined") {
    localStorage.removeItem("persist:root")
  }
}
export const ClearToken = () => {
  if (typeof window !== "undefined") {
    localStorage.removeItem("7ea2ceccf2e673357879358b18e3145b")
  }
}
export const SetToken = token => {
  if (typeof window !== "undefined") {
    localStorage.setItem("7ea2ceccf2e673357879358b18e3145b", token)
  }
}
