import React, { useState, useEffect } from "react"
import Styled from "styled-components"
import Img from "gatsby-image"
import { useForm } from "react-hook-form"
import Axios from "axios"
import Slider from "react-input-slider"

import Layout from "@components/Layout"
import SEO from "@components/Seo"
import BackgroundImg from "@components/BackgroundImg"
import { GridLayout, GridItem } from "@components/Masonry"
import ScrollToLink from "@components/ScrollToLink"
import Menu from "@components/Menu"
import {
  Check,
  Email,
  Phone,
  Facebook,
  Instagram,
} from "@components/Fontawesome"
import ExternalLink from "@components/ExternalLink"
import { Queries } from "@components/StyledElements"
import { TestimonialCarousel } from "@components/Carousels"
import { useIndexImages } from "../queryHooks"
import {
  PRICE,
  PER_500_SQFT,
  SQFT_OPTIONS,
  SECTIONS,
  SERVERLESS_URL,
  TESTIMONIALS,
} from "../config"
import { ConvertSqft, ConvertDollars } from "../utils"

//! Begin Styled Components
const Container = Styled.div`
    display: grid;
    grid-template-columns: 1fr;
    grid-template-areas:
    "nav"
    "header"
    "story"
    "services"
    "estimatequote"
    "examples"
    "testimonials"
    "footer";
    justify-items: stretch;
`
//!----------------------------------------------------------------
const Nav = Styled.nav`
    position: fixed;
    top: 0px;
    left: 0px;
    right: 0px;
    z-index: 99;
    grid-area: nav;
    background-color: ${({ theme }) => theme.black};
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
`
const NavLogo = Styled(Img)`
    width: 75px;
`
const NavItems = Styled.ul`
    margin-right: 20px;
    list-style-type: none;
    display: flex;
    flex-direction: row;
    ${Queries.mobile.down} {
        display: none;
    }
`
const NavItem = Styled.li`
    margin-left: 10px;
    a {
        cursor: pointer;
        text-transform: uppercase;
        color: ${({ theme }) => theme.white};
        &:hover {
            text-decoration: underline;
        }
    }
`
//!----------------------------------------------------------------
const Header = Styled.header`
    grid-area: header;
    place-self: stretch;
    
`
const HeaderLogo = Styled(Img)`
    margin: 100px auto 0px auto;
    width: 450px;
    ${Queries.mobile.down} {
        width: 350px;
    }
`
//!----------------------------------------------------------------
const SpecializeSection = Styled.section`
    grid-area: story;
    padding: 20px;
    background-color: ${({ theme }) => theme.black};
    color: ${({ theme }) => theme.white};
    place-self: stretch;
    display: grid;
    grid-template-rows: 1fr;
    grid-template-columns: 1fr 1fr;
    grid-template-areas:
    "specializeleft specializeright";
    grid-gap: 20px;
    ${Queries.mobile.down} {
        grid-template-columns: 1fr;
        grid-template-areas:
        "specializeleft"
        "specializeright";
    }
`
const Specialize = Styled.div`
    grid-area: specializeleft;
    h2 {
        text-align: center;
    }
    p {
        padding: 15px;
    }
    p::first-letter {
        font-weight: bold;
        font-size: 30px;
    }
`
const Contact = Styled.div`
    grid-area: specializeright;
    h2 {
        text-align: center;
    }
    fieldset {
        display: flex;
        flex-direction: column;
        border: none;
    }
    form {
        label {
            margin-bottom: 5px;
        }
        textarea, input {
            background-color: #161616;
            color: ${({ theme }) => theme.white};
            border: none;
            padding: 10px;
            margin-bottom: 5px;
        }
        textarea {
            resize: none;
        }
        span {
            text-align: center;
            color: red;
        }
        button {
            cursor: pointer;
            display: inline-block;
            padding: 0.35em 1.2em;
            border: 0.1em solid #FFFFFF;
            margin: 0 0.3em 0.3em 0;
            border-radius: 0.12em;
            box-sizing: border-box;
            text-decoration: none;
            font-family: 'Roboto', sans-serif;
            font-weight: 300;
            color: #FFFFFF;
            background-color: transparent;
            text-align: center;
            transition: all 0.2s;
            &:hover {
                color: #000000;
                background-color: #FFFFFF;
            }
        }
    }
    
`
const Success = Styled.span`
    margin-right: 5px;
    padding: 0.35em 1.2em;
    color: #155724 !important;
    background-color: #d4edda;
    border-color: #c3e6cb;
`
const Error = Styled.span`
    margin-right: 5px;
    padding: 0.35em 1.2em;
    color: #721c24;
    background-color: #f8d7da;
    border-color: #f5c6cb;
`
//!----------------------------------------------------------------
const ServicesSection = Styled.section`
    padding: 20px;
    background-color: ${({ theme }) => theme.white};
    grid-area: services;
    justify-items: center;
    display: grid;
    grid-template-columns: 1fr;
    grid-template-areas:
    "servtop"
    "servbottom";
    grid-gap: 20px;
`
const ServicesSectionTop = Styled.div`
    grid-area: servtop;
    place-self: center;
    h3 {
        padding-top: 10px;
        text-align: center;
    }
    h2 {
        color: ${({ theme }) => theme.black};
        font-size: 36px;
        text-align: center;
        margin-top: -15px;
    }
    p {
        font-style: italic;
        max-width: 450px;
        word-wrap: break-word;
        text-align: center;
    }
`
const Box = Styled.div`
    border: 1px solid ${({ theme }) => theme.primary};
`
const ServicesSectionBottom = Styled.div`
    grid-area: servbottom;
    display: grid;
    grid-template-columns: repeat(6, 1fr);
    grid-template-areas:
    ". buildsvg consultsvg managementsvg hammersvg .";
    grid-gap: 40px;
    div {
        display: flex;
        flex-direction: column;
        height: 150px;
        align-items: center;
        justify-content: space-between;
    }
    div:nth-child(1) {
        grid-area: buildsvg;
        text-align: center;
    }
    div:nth-child(2) {
        grid-area: consultsvg;
        text-align: center;
    }
    div:nth-child(3) {
        grid-area: managementsvg;
        text-align: center;
    }
    div:nth-child(4) {
        grid-area: hammersvg;
        text-align: center;
    }
    ${Queries.mobile.down} {
        grid-template-columns: repeat(2, 1fr);
        grid-template-areas:
        "buildsvg consultsvg"
        "managementsvg hammersvg";
    }
`
//!----------------------------------------------------------------
const EstimateQuoteSection = Styled.section`
    grid-area: estimatequote;
    background-color: ${({ theme }) => theme.black};
    display: grid;
    grid-template-columns: repeat(4, 1fr);
    grid-template-areas:
    ". estimateleft estimateright ."
    ". estimatemiddle estimatemiddle .";
    grid-gap: 40px;
    div:nth-child(1) {
        grid-area: estimateleft;
        text-align: center;
        h2 {
            color: ${({ theme }) => theme.white};
        }
    }
    div:nth-child(2) {
        grid-area: estimateright;
        text-align: center;
        display: flex;
        justify-content: center;
        align-items: center;
        flex-direction: column;
        h2 {
            color: ${({ theme }) => theme.white};
            margin-bottom: 15px;
        }
        div {
            align-items: flex-start;
        }
        span {
            margin-top: 15px;
            color: ${({ theme }) => theme.white};
        }
    }
    div:nth-child(3) {
        grid-area: estimatemiddle;
        p {
            padding: 20px;
            text-align: center;
            color: ${({ theme }) => theme.white};
        }
    }
    ${Queries.mobile.down} {
        grid-template-columns: repeat(1, 1fr);
        grid-template-areas:
        "estimateleft"
        "estimateright"
        "estimatemiddle";
    }
`
//!----------------------------------------------------------------
const ExamplesSection = Styled.section`
    background-color: ${({ theme }) => theme.black};
    grid-area: examples;
    hr {
        margin-top: 40px;
        &:last-child {
            margin-bottom: 40px;
        }
    }
    h2 {
        text-align: center;
        font-size: 40px;
        color: ${({ theme }) => theme.white};
    }
`
const ExampleItem = Styled(Img)`
    width: 250px;
`
//!----------------------------------------------------------------
const TestimonialsSection = Styled.section`
    grid-area: testimonials;
    overflow-x: hidden;
    h2 {
        text-align: center;
        font-size: 30px;
    }
`
//!----------------------------------------------------------------
const Footer = Styled.footer`
    background-color: #1f1f1f;
    grid-area: footer;
    display: grid;
    grid-template-areas:
    "footertop"
    "footermiddle"
    "footerbottom"
    "footercopy";
`
const FooterTop = Styled.div`
    grid-area: footertop;
    margin: 0 auto;
    background-color: ${({ theme }) => theme.black};
    width: 80%;
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-template-areas:
    "footertopleft footertopright";
    div:nth-child(1) {
        grid-area: footertopleft;
        width: 100%;
        justify-self: center;
        align-self: center;
        padding: 20px;
        a {
            display: grid;
            grid-template-rows: 0.1fr 0.1fr;
            grid-template-columns: 1fr 1fr 1fr;
            grid-template-areas:
            "emailsvg emailspan email";
            svg {
                grid-area: emailsvg;
                justify-self: end;
            }
            span {
                grid-area: emailspan;
                justify-self: center;
                border-left: 1px solid white;
                height: 30px;
            }
            div {
                grid-area: email;
                justify-self: start;
                display: grid;
                grid-template-columns: 0.5fr;
                grid-template-rows: 0.1fr 0.1fr;
                grid-template-areas:
                "emailme"
                "emailaddr";
                h4 {
                    grid-area: emailme;
                    margin: 0px 0px 10px 0px;
                }
                h6 {
                    grid-area: emailaddr;
                    margin: 0px;
                }
            }
        }
    }
    div:nth-child(2) {
        grid-area: footertopright;
        width: 100%;
        align-self: center;
        justify-self: center;
        padding: 20px;        
        a {
            display: grid;
            grid-template-rows: 0.1fr 0.1fr;
            grid-template-columns: 1fr 1fr 1fr;
            grid-template-areas:
            "phonesvg phonespan phone";
            svg {
                grid-area: phonesvg;
                justify-self: end;
            }
            span {
                grid-area: phonespan;
                justify-self: center;
                border-left: 1px solid white;
                height: 30px;
            }
            div {
                grid-area: phone;
                justify-self: start;
                display: grid;
                grid-template-columns: 1fr;
                grid-template-rows: 0.1fr 0.1fr;
                grid-template-areas:
                "phoneme"
                "phoneaddr";
                h4 {
                    grid-area: phoneme;
                    margin: 0px 0px 10px 0px;
                }
                h6 {
                    grid-area: phoneaddr;
                    margin: 0px;
                }
            }
        }
    }
    ${Queries.mobile.down} {
        width: 100%;
        grid-template-columns: 1fr;
        grid-template-areas:
        "footertopleft"
        "footertopright";
        div:nth-child(1) {
            grid-area: footertopleft;
            width: 100%;
            justify-self: center;
            align-self: center;
            padding: 20px;
            a {
                display: grid;
                grid-template-rows: 0.1fr 0.1fr;
                grid-column-gap: 20px;
                grid-template-columns: 0.5fr 1fr;
                grid-template-areas:
                "emailsvg email";
                svg {
                    grid-area: emailsvg;
                    justify-self: end;
                }
                span {
                    display: none;
                    grid-area: emailspan;
                    justify-self: center;
                    border-left: 1px solid white;
                    height: 30px;
                }
                div {
                    grid-area: email;
                    justify-self: start;
                    display: grid;
                    grid-template-columns: 0.5fr;
                    grid-template-rows: 0.1fr 0.1fr;
                    grid-template-areas:
                    "emailme"
                    "emailaddr";
                    h4 {
                        grid-area: emailme;
                        margin: 0px 0px 10px 0px;
                    }
                    h6 {
                        grid-area: emailaddr;
                        margin: 0px;
                    }
                }
            }
        }
        div:nth-child(2) {
            grid-area: footertopright;
            width: 100%;
            align-self: center;
            justify-self: center;
            padding: 20px;        
            a {
                display: grid;
                grid-template-rows: 0.1fr 0.1fr;
                grid-template-columns: 0.5fr 1fr;
                grid-column-gap: 20px;
                grid-template-areas:
                "phonesvg phone";
                svg {
                    grid-area: phonesvg;
                    justify-self: end;
                }
                span {
                    display: none;
                    grid-area: phonespan;
                    justify-self: center;
                    border-left: 1px solid white;
                    height: 30px;
                }
                div {
                    grid-area: phone;
                    justify-self: start;
                    display: grid;
                    grid-template-columns: 1fr;
                    grid-template-rows: 0.1fr 0.1fr;
                    grid-template-areas:
                    "phoneme"
                    "phoneaddr";
                    h4 {
                        grid-area: phoneme;
                        margin: 0px 0px 10px 0px;
                    }
                    h6 {
                        grid-area: phoneaddr;
                        margin: 0px;
                    }
                }
            }
        }
    }
`
const FooterMiddle = Styled.div`
    grid-area: footermiddle;
    width: 80%;
    margin: 0 auto;
    align-self: center;
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-template-areas:
    "footerlogo footernav";
    div:nth-child(1) {
        grid-area: footerlogo;
        justify-self: center;
    }
    div:nth-child(2) {
        grid-area: footernav;
        display: grid;
        align-items: center;
        justify-self: center;
    }
    ${Queries.mobile.down} {
        grid-template-columns: 1fr;
        grid-template-areas:
        "footerlogo"
        "footernav";
    }
`
const FooterItems = Styled.ul`
    list-style-type: none;
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-template-areas:
    "item1 item2"
    "item3 item4"
    "item5 item6";
    grid-gap: 20px;
    
    ul:nth-child(1) {
        grid-area: item1;
        align-self: center;
    }
    ul:nth-child(2) {
        grid-area: item2;
    }
    ul:nth-child(3) {
        grid-area: item3;
    }
    ul:nth-child(4) {
        grid-area: item4;
    }
    ul:nth-child(5) {
        grid-area: item5;
    }
    ul:nth-child(6) {
        grid-area: item6;
    }
`
const FooterItem = Styled.li`
    a {
        cursor: pointer;
        font-size: 20px;
        text-transform: uppercase;
        color: ${({ theme }) => theme.white};
        &:hover {
            text-decoration: underline;
        }
    }
`
const FooterLogo = Styled(Img)`
    width: 400px;
    ${Queries.mobile.down} {
        width: 300px;
    }
`
const FooterBottom = Styled.div`
    grid-area: footerbottom;
    background-color: ${({ theme }) => theme.black};
    width: 100%;
    align-self: center;
    padding: 20px;
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-template-areas:
    "footerbottomleft footerbottomright";
    div:nth-child(1) {
        grid-area: footerbottomleft;
        justify-self: end;
    }
    div:nth-child(2) {
        grid-area: footerbottomright;
        justify-self: start;
    }
`
const FooterCopy = Styled.div`
    grid-area: footercopy;
    align-self: center;
    text-align: center;
    h4 {
        color: ${({ theme }) => theme.white};
    }
`
//! ----------------------------------------------------------------
const IndexPage = () => {
  const [slider, setSlider] = useState({ x: 0 })
  const [estimatedQuote, setEstimatedQuote] = useState(PRICE)
  //? ----------------------------------------------------------------
  const [loading, setLoading] = useState(false)
  const [success, setSuccess] = useState("")
  const [error, setError] = useState("")
  const { register, handleSubmit, errors } = useForm()
  const { header, headerLogo, navLogo, logo, examples } = useIndexImages()
  const onSubmit = async formData => {
    try {
      setLoading(true)
      setSuccess("")
      setError("")
      const { data } = await Axios.post(
        SERVERLESS_URL,
        JSON.stringify(formData)
      )
      setSuccess(data)
      setLoading(false)
    } catch (error) {
      console.log("Contact Error: ", error)
      setError(error)
      setLoading(false)
    }
  }

  const handleSliderChange = x => {
    setSlider({ x })
    let total = PRICE
    const delta = SQFT_OPTIONS[x]
    total += delta * PER_500_SQFT // addition size home
    setEstimatedQuote(total)
  }

  return (
    <Layout>
      <SEO title="Home" />
      <Container>
        <Nav>
          <NavLogo fluid={navLogo} alt={`Southern Utah Home Inspection Logo`} />
          <Menu />
          <NavItems>
            {SECTIONS.map(section => (
              <NavItem key={`top-nav-${section.name}`}>
                <ScrollToLink to={section.path}>{section.name}</ScrollToLink>
              </NavItem>
            ))}
          </NavItems>
        </Nav>
        <Header>
          <BackgroundImg fluid={header} alt={`Southern Utah Home Inspection`}>
            <HeaderLogo
              fluid={headerLogo}
              alt={`Southern Utah Home Inspection Logo`}
            />
          </BackgroundImg>
        </Header>
        <SpecializeSection>
          <Specialize id="about">
            <h2>About Us</h2>
            <p>
              We believe that buying a home is one of the single most important
              moments in a person's life. We want to do our part in the process
              to make it as easy and painless as possible!
            </p>
            <p>
              Are you looking to buy a house? The peace of mind a home
              inspection can provide when buying a home is priceless, and we
              believe it is essential to make sure you have all the information
              before you make such a significant decision.
            </p>
            <p>
              Whether this is your first time purchasing a home or your 10th,
              our certified and licensed home inspectors will give you the peace
              of mind you deserve.
            </p>
            <p>
              Southern Utah Home Inspection is here to help you throughout the
              process of purchasing or selling your home!
            </p>
          </Specialize>
          <Contact id="contact">
            <form onSubmit={handleSubmit(onSubmit)}>
              <h2>Contact Us</h2>
              <fieldset>
                <label htmlFor="name">Name:</label>
                <input
                  type="text"
                  name="name"
                  placeholder="Name"
                  ref={register({ required: "Name is required" })}
                />
                {errors.name && <span>{errors.name.message}</span>}
              </fieldset>
              <fieldset>
                <label htmlFor="email">Email:</label>
                <input
                  type="email"
                  name="email"
                  placeholder="Email"
                  ref={register({
                    required: "Email is required",
                    pattern: {
                      value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i,
                      message: "Enter a valid email",
                    },
                  })}
                />
                {errors.email && <span>{errors.email.message}</span>}
              </fieldset>
              <fieldset>
                <label htmlFor="phone">Phone:</label>
                <input
                  type="tel"
                  name="phone"
                  placeholder="Phone"
                  ref={register({
                    required: "Phone is required",
                  })}
                />
                {errors.phone && <span>{errors.phone.message}</span>}
              </fieldset>
              <fieldset>
                <label htmlFor="message">Message:</label>
                <textarea
                  name="message"
                  placeholder="Message"
                  rows="6"
                  cols="25"
                  ref={register({ required: "Message is required" })}
                />
                {errors.message && <span>{errors.message.message}</span>}
              </fieldset>
              <fieldset>
                <button type="submit" disabled={loading}>
                  {loading ? "Sending..." : "Submit Inquiry"}
                </button>
                {error && <Error>{error}</Error>}
                {success && <Success>{success}</Success>}
              </fieldset>
            </form>
          </Contact>
        </SpecializeSection>
        <ServicesSection id="services">
          <ServicesSectionTop>
            <Box>
              <h3>What We Can Do?</h3>
              <h2>Our Services</h2>
            </Box>
            <p>
              We offer a variety of home inspection services for home buyers,
              home owners, investors, renters and more.
            </p>
          </ServicesSectionTop>
          <ServicesSectionBottom>
            <div>
              <Check width={75} title={`Pre-Purchase Home Inspections`} />
              <h5>Pre-Purchase Home Inspections</h5>
            </div>
            <div>
              <Check width={75} title={`Pre-Listing Home Inspections`} />
              <h5>Pre-Listing Home Inspections</h5>
            </div>
            <div>
              <Check
                width={75}
                title={`New Construction Final Home Inspections`}
              />
              <h5>New Construction Final Home Inspections</h5>
            </div>
            <div>
              <Check width={75} title={`11th Month Warranty Inspections`} />
              <h5>11th Month Warranty Inspections</h5>
            </div>
          </ServicesSectionBottom>
        </ServicesSection>
        <EstimateQuoteSection id="quote">
          <div>
            <h2>Estimated Quote</h2>
            <h2>${ConvertDollars(estimatedQuote)}</h2>
          </div>

          <div>
            <h2>Home's Estimated Size</h2>
            <Slider
              styles={{
                track: {
                  backgroundColor: "rgba(255,255,255,0.5)",
                },
                active: {
                  backgroundColor: "#0084ff",
                },
                thumb: {
                  width: 30,
                  height: 30,
                },
              }}
              axis="x"
              xstep={500}
              xmin={0}
              xmax={20000}
              x={slider.x}
              onChange={({ x }) => handleSliderChange(x)}
            />
            <span>{ConvertSqft(slider.x)} ft&#178;</span>
          </div>
          <div>
            <p>
              For homes less than or equal to 3,000ft&#178; price is $350.00.
            </p>
            <p>
              For homes greater than 3,000ft&#178; or for historical aged
              properties price increases by $50.00 per 500ft&#178;.
            </p>
          </div>
        </EstimateQuoteSection>
        <ExamplesSection id="examples">
          <hr />
          <h2>Before/After Projects</h2>
          <GridLayout>
            {examples.map(({ node }) => (
              <GridItem key={node.name}>
                <ExampleItem
                  fluid={node.childImageSharp.fluid}
                  alt={node.name}
                />
              </GridItem>
            ))}
          </GridLayout>
          <hr />
        </ExamplesSection>
        <TestimonialsSection id="testimonials">
          <h2>Client Testimonials</h2>
          <TestimonialCarousel testimonials={TESTIMONIALS} />
        </TestimonialsSection>
        <Footer>
          <FooterTop>
            <div>
              <ExternalLink email={`adam@southernutahhomeinspection.com`}>
                <Email color={"#fff"} width={50} />
                <span />
                <div>
                  <h4>Email Me</h4>
                  <h6>adam@southernutahhomeinspection.com</h6>
                </div>
              </ExternalLink>
            </div>
            <div>
              <ExternalLink phone={`14352726098`}>
                <Phone color={"#fff"} width={50} />
                <span />
                <div>
                  <h4>Call Me</h4>
                  <h6>(435) 272-6098</h6>
                </div>
              </ExternalLink>
            </div>
          </FooterTop>
          <FooterMiddle>
            <div>
              <FooterLogo fluid={logo} alt={`Southern Utah Home Inspection`} />
            </div>
            <div>
              <FooterItems>
                {SECTIONS.map(section => (
                  <FooterItem key={`footer-${section.name}`}>
                    <ScrollToLink to={section.path}>
                      {section.name}
                    </ScrollToLink>
                  </FooterItem>
                ))}
              </FooterItems>
            </div>
          </FooterMiddle>
          <FooterBottom>
            <div>
              <ExternalLink
                href={`https://www.facebook.com/southernutahhomeinspection`}
              >
                <Facebook width={50} />
              </ExternalLink>
            </div>
            <div>
              <ExternalLink
                href={`https://www.instagram.com/southernutahhomeinspection`}
              >
                <Instagram width={50} />
              </ExternalLink>
            </div>
          </FooterBottom>
          <FooterCopy>
            <h4>
              &copy; {new Date().getFullYear()}, Southern Utah Home Inspection
            </h4>
          </FooterCopy>
        </Footer>
      </Container>
    </Layout>
  )
}

export default IndexPage
